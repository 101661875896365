 import React from 'react'

 import styled from '@emotion/styled'

 class Icon extends React.Component {
     
    renderIconText(){
        return this.props.children ? <div style={{textAlign: "center"}}>{this.props.children}</div> : null
    }

     render() {
         return(
             <article className={this.props.className}>
                <Header><Image src={this.props.src}  alt={this.props.alt} /></Header>
                { this.renderIconText() }
             </article>
         )
     }
 }

 export default Icon

 const Image = styled.img`
    height: 280px;
    width: auto;
 `

 const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
 `