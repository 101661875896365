import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';

import waveImg from '../assets/images/blue-wave.svg'

class MemberModal extends React.Component {
  static propTypes = {
    memberData: PropTypes.array.isRequired, // eslint-disable-line
  }

  constructor(props) {
    super(props);

    this.state = {
      showMemberModal: false, 
      selectedMember: null,
    };

  }
  
  render() {
    const { memberData } = this.props;
    const { showMemberModal, selectedMember } = this.state;

    return (
      <React.Fragment>
        <MemberContainer className="container">
          {memberData.map(member => (
            <MemberCard key={member.node.id}>
              <Img fixed={member.node.frontmatter.bioImage.childImageSharp.fixed} />
              <h1>{member.node.frontmatter.name}</h1>
              <h3>{member.node.frontmatter.title}</h3>
              <p>{member.node.frontmatter.excerpt}</p>
              <SpecialButton type="button" onClick={() => this.setState({ showMemberModal: true, selectedMember: member })}>Keep Reading</SpecialButton>
            </MemberCard>
          ))}
          
        </MemberContainer>
        {showMemberModal && (
          <MemberDialog tabindex="0" aria-label={selectedMember.node.frontmatter.name + 'Information'} onDismiss={() => this.setState({ showMemberModal: false })}>
            <ModalHeader>
              <Img fixed={selectedMember.node.frontmatter.bioImage.childImageSharp.fixed} />
              <div className="header-content">
                <h1>{selectedMember.node.frontmatter.name}</h1>
                <h4>{selectedMember.node.frontmatter.title}</h4>
              </div>
            </ModalHeader>   
            <div dangerouslySetInnerHTML={{ __html: selectedMember.node.html }}></div>
            <CloseButton type="button" onClick={() => this.setState({ showMemberModal: false })}>
              <span aria-hidden>×</span>
            </CloseButton>
          </MemberDialog>
        )}
      </React.Fragment>
    )
  }
}

export default MemberModal;


const ModalHeader = styled.div`
  display: flex;
  align-items: center;

  .header-content {
    margin-left: 40px;
    max-width: 300px;

    h4 {
      font-weight: normal;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    .header-content {
      margin: 0;
      margin-top: 40px;
    }
  }
`

const MemberContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const MemberCard = styled.aside`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 300px;
  align-items: center;
  margin-bottom: 100px;

  h3 {
    padding: 0 30px;
  }

`

const MemberDialog = styled(Dialog)`
  position: relative;
  background: url(${waveImg});
  color: #ffffff;
  background-size: cover;
  border-radius: 20px;
  padding: 40px;
  height: auto;
  width: 80vw;
  max-width: 800px;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-name: modalTransition;
  overflow: hidden;
  transform: scale(0);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  

  @keyframes modalTransition {
    0% {
        opacity: 0;
        transform: scale(0);

    }
    100% {  
        opacity:1;
        transform: scale(1);
    }
    
}

`

const CloseButton = styled.span`
  position: absolute;
  top: 1em;
  right: 1em;
  color: white;
  z-index: 9999;
  font-family: sans-serif;
  font-size: 1.4rem;
  line-height: 1rem;
  cursor: pointer;

  &:hover {
      text-shadow: 0 0 4px rgba(0,0,0,0.3);
  }
`

const SpecialButton = styled.button`
    background: #8DC63F;
    border: none;
    border-radius: 50px;
    padding: .5em 1.5em;
    color: white;
    cursor: pointer;
    font-size: 1em;


    box-shadow: 2px 2px 5px -1px rgba(0,0,0,0.15);
    transition: box-shadow 0.1s linear;

    &:hover {
        box-shadow: none;
    }

    @media (max-width: 767px) {
        font-size: 0.9em;
    }
`