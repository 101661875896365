import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import MemberModal from './memberModal'

const Members = () => (
  <StaticQuery
    query={graphql`
      query boardMembersQuery {
        allMarkdownRemark(filter: {frontmatter: {posttype: {eq: "member"}}}, sort: {fields: frontmatter___order}) {
          edges {
            node {
              id
              html
              frontmatter {
                name
                order
                path
                posttype
                title
                excerpt
                bioImage {
                  childImageSharp {
                    fixed(width: 225, height: 225) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}

    render={data => 
      <section>
        <div className="container">
          <div className="row center-sm">
            <div className="col-sm-10">
              <h1 style={{fontSize: "2.3em", margin: "0 0 2rem"}}>Board Members</h1>
            </div>
          </div>
        </div>
        <MemberModal memberData={data.allMarkdownRemark.edges} />
      </section>
    }
    />
);
export default Members;