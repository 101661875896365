import React from "react"
import styled from '@emotion/styled'

import Layout from "../components/layout"

import SEO from "../components/seo"

import Icon from "../components/icon"
import Members from '../components/members'

import TopRight from "../assets/images/top-right.svg"
import MobileImageBottom from "../assets/images/mobile-header-bottom.svg"
import HeroLogo from "../assets/images/nata-large-logo.svg"
import TRGLogo from "../assets/images/trg-logo.svg"
import YavapaiLogo from "../assets/images/yavapai-college-logo.svg"
import ConsentSolutionsLogo from "../assets/images/consent-solutions-logo.svg"
import EARULogo from "../assets/images/earu-logo.svg"
import EightfoldLogo from "../assets/images/eightfold-logo.svg"
import EightfoldITLogo from "../assets/images/eightfold-it-logo.svg"
import PageImage from "../assets/images/prescott.png"

import DeskIcon from "../assets/images/icons/desk-no-bg.svg"
import PlaneIcon from "../assets/images/icons/plane-no-bg.svg"
import ServerIcon from "../assets/images/icons/servers-no-bg.svg"
import BackrdoundImage from "../assets/images/clay-banks-iTsNwMB-79M-unsplash 1.jpg"

const SpecialSection = styled.section`
  position:relative;
  background: url("${BackrdoundImage}")  no-repeat center / cover;
  min-height: 35vw;
  margin-bottom: 2rem;

  @media (max-width: 767px) {
    background-color:#03D3C6;
    margin-bottom: 20vw;
    &:after {

    background: url(${MobileImageBottom});
    content: "";
    position: absolute;
    background-size: auto 100%;
    width: 100%;
    height: 35vw;
    padding-top: 8vw;
    bottom: -8vw;
    }
  }
`

const SpecialImage = styled.img`
  position:absolute;
  top:0;
  right:0;
  height:50vw;

  @media (max-width: 767px) {
    display: none;
  }
`

const SpecialParagraph = styled.p`
  font-size: 24px;
  line-height: 1.5;
  padding: 0 2em;
  margin-bottom: 80px;

  @media screen and (max-width: 768px) {
    padding: 0 .5em;
    margin-bottom: 60px;
  }
`


const OffsetSection = styled.section`
  display: flex;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  color: #ffffff;
  font-size: 18px;
  line-height: 26px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  .left-col {
    background-color: #48A49D;
    margin-right: -35px;
    padding: 40px 80px;
    display: flex;
    flex: 1 1 60%;
    justify-content: flex-end;

    .content {
      max-width: 800px;
    }

    @media screen and (max-width: 1000px) {
      justify-content: flex-start;
      width: auto;
      padding: 40px 20px;
      margin-right: 0;
      justify-content: flex-start;
      flex: 1;
      padding: 20px;

      .content {
        width: 100%;
      }
    }
  }

  .right-col {
    background-color: #9AC355;
    margin-top: 45px;
    margin-bottom: 15px;
    display: flex;
    flex: 1 1 45%;
    justify-content: flex-start;
    padding: 40px 80px;
    width: auto;

    .content {
      max-width: 600px;
    }

    @media screen and (max-width: 1000px) {
      margin-top: 0;
      padding: 40px 20px;

      .content {
        width: 100%;
      }
    }
  }

`

const ReverseOffsetSection = styled.div`
    display: flex;
    margin-top: 0;
    margin-bottom: 100px;
    color: #ffffff;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }

    .left-col {
      background-color: #9AC355;
      width: 40%;
      margin-right: -35px;
      margin-top: 20px;
      padding: 40px 80px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .content {
        max-width: 800px;
      }

      @media screen and (max-width: 1000px) {
        display: none;
        margin: 0;

        .content {
          max-width: none;
        }
      }

    }

    .right-col {
      background-color: #48A49D;
      width: 75%;
      height: 800px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px 80px;

      div {
        max-width: 600px;
      }

      @media screen and (max-width: 1000px) {
        margin-top: 0;
        height: auto;
        width: auto;
        padding: 40px 20px;
      }
    }

`

const SpecialHeading = styled.h2`
  font-size: 30px;
  font-weight: lighter;

`

const LogoDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: .5fr 1fr 1fr;
  grid-gap: 80px;
  justify-items: center;
  align-items: center;
  margin-top: 60px;

  img {
    width: 100%;
    max-height: 100px;
  }

  img:nth-of-type(1), img:nth-of-type(4) {
    max-height: none;
    max-width: 150px;
  }

`

const TextBlockImageSection = styled.section`
  position: relative;
  color: #ffffff;
  margin-top: 100px;
  margin-bottom: 100px;

  img {
    width: 100%;
  }

  .content {
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: -5%;
    transform: translateY(-50%);
    background-color: #48A49D;
    padding: 40px;
  }

  h2 {
    max-width: 250px;
    margin-top: 0;
  }

  @media screen and (max-width: 1400px) {
    .content {
      left: 0;
    }
  }

  @media screen and (max-width: 1100px) {
    .content {
      max-width: 100%;
      position: relative;
      transform: translateY(0);
    }
  }

`


const StyledIcon = styled(Icon)`

  display: none;
  
  @media screen and (max-width: 768px) {
    display: block;
    margin: 40px 0;
  }
  
`

const AboutPage = ({data}) => {

    // const members = data.allMarkdownRemark.edges;

    return(
    
      <Layout>

      <SEO 
        title="About NATA"
        // description="About Us Meta Description"
      />
      
      <SpecialSection className="intro">
        
        <SpecialImage alt="Northern Arizona Technology Alliance" src={TopRight} style={{maxHeight: "900px"}} />
        <div className="container" style={{padding:"12vw 1.5rem 8vw", position: "relative", zIndex: "2"}}>
          
          <div className="row center-sm">
            <div className="col-sm-10">
              {/* <img src={HeroLogo} alt="Northern Arizona Technology Alliance" style={{display: "block", width: "100%", height: "auto"}} /> */}
            </div>
          </div>
        </div>

      </SpecialSection>
  
      <section className="container">
        <div className="row center-sm">
          <div className="col-sm-12 col-lg-10" style={{textAlign: 'center'}}>
            <h1 style={{ fontSize: '54px', fontWeight: 'regular'}}>Norther Arizona Technology Alliance</h1>
            <SpecialParagraph>NATA was founded with the singular goal of building technology opportunity in Northern Arizona. Creating cohesive and strategic programs for making our region more adept with the use and implementation of every day tech.</SpecialParagraph>
          </div>
        </div>
      </section>

      <section>
      <div className="container">
        <div className="row center-sm">
          <div className="col-sm-10">
            <h1 style={{fontSize: "2.3em", margin: "3rem 0 2rem"}}>Initiatives</h1>
          </div>
        </div>
      </div>
      <ReverseOffsetSection className="">
          <div className="left-col">
            <div className="content">
              <Icon className="" src={ServerIcon} alt="Server rack illustration" />
             
              <Icon className="" src={PlaneIcon} alt="Illustration of a plane" />
                
              <Icon className="" src={DeskIcon} alt="Technological workforce illustration" />
  
            </div>
          </div>

          {/* <ReverseOffsetSection className="">
            <div className="left-col">
              <div className="content">
                
                <Icon className="" src={ServerIcon} alt="Server rack illustration" />
                
                <Icon className="" src={PlaneIcon} alt="Illustration of a plane" />
                  
                <Icon className="" src={DeskIcon} alt="Technological workforce illustration" />
    
              </div>
            </div> */}
            <div className="right-col">
                <div className="">
                  <SpecialHeading>Digital Technology</SpecialHeading>
                  <p>Technology is growing most rapidly in the digital space. Our location is ideal to serve a broad audience and help digital businesses.</p>
                  <StyledIcon className="" src={ServerIcon} alt="Server rack illustration" />
                </div>
                <div className="">
                  <SpecialHeading>Aerospace</SpecialHeading>
                  <p>Prescott has a storied history as the host to Embry-Riddle Aeronautical University. As home to other organizations like Cobham and Eviation, our heritage grows even stronger.</p>
                  <StyledIcon className="" src={PlaneIcon} alt="Illustration of a plane" />
                </div>
                <div className="">
                  <SpecialHeading>Workforce</SpecialHeading>
                  <p>Northern Arizona provides a strong base for a variety of work opportunities. With a high quality of life quotient and a focus on making career building more accessible, we want to be the future of tech employment.</p>
                  <StyledIcon className="" src={DeskIcon} alt="Technological workforce illustration" />
                </div>
            </div>
          </ReverseOffsetSection>
      {/* </section> */}
      </section>

      {/*<BoardMembersSection className="container">
        <div className="row">
          <div className="content col-sm-12">
          {members.map(member => ( 
            <Member key={member.node.id}
              bioImage={member.node.frontmatter.bioImage}
              name={member.node.frontmatter.name}
              title={member.node.frontmatter.title}
              excerpt={member.node.frontmatter.excerpt}
              html={member.node.html}
              memberId={member.node.id}
            />
          ))}
          </div>
        </div>
          </BoardMembersSection>*/}

      <Members />
    
  

  
      <OffsetSection className="">
          <div className="left-col">
            <div className="content">
              <SpecialHeading>NATA Mission Charter</SpecialHeading>
              <p>NATA has the goal of educating and raising awareness around the resources in the area involving technology. The goal is educating and bringing knowledge to both younger professionals and the business owners in the area of technology. NATA plans to create a sustainable technology industry by developing technical talent. Organizational goals involve consulting projects within the community, assisting in developing educational technology for youth, business owners, and the tech community at-large. Additionally, the objective is to achieve the development of resources for rural and economically self-reliant micro-urban areas in specific technologies that lead to economic independence. NATA is creating a network of individuals and businesses located in Northern Arizona in different technology industries and helping to create mutually beneficial relationships by providing networking events and engagements focused on development and educational opportunities.</p>
            </div>
          </div>
          <div className="right-col">
            <div className="content">
              <SpecialHeading>Introduction</SpecialHeading>
              <p>NATA was founded in 2019 with the goal of establishing a key foothold in the American Southwest. Primarily, our mission is developing technology resources in Northern Arizona, with the goal of the organization focused on spreading influence and developing opportunity around the resources and opportunities to build technology companies. The core goal of the founding members is to unite the education communities, government organizations, and various private enterprises underneath a single charter and drive technological development in all of it’s capacities in the region.</p>
            </div>
          </div>
      </OffsetSection>
  
      <TextBlockImageSection className="container">
        <div className="row">
          <img src={PageImage} alt="photograph of prescott arizona"/>
          <div className="content col-sm-12">
            <SpecialHeading>Northern Arizona is the Frontier</SpecialHeading>
            <p>Northern Arizona has been a high growth region for several decades, bucking national trends for rural regions. As our corridor of Arizona has continued to expand with Flagstaff, Prescott, and the Cottonwood/Sedona area experiencing double digit growth, many areas in technology have lagged behind, in spite of high quality education institutions, as well as many other areas of opportunity. Technology until recently had not been at the forefront for the local population centers. Northern Arizona Technology Alliance is seeking to actively change that and redefine the region as a high opportunity center for startups and large scale organizations alike.</p>
          </div>
        </div>
      </TextBlockImageSection>
  
      <section className="container">
        <div className="row center-xs">
          <div className="col-md-12" style={{textAlign: "center", padding: "0 0 3em"}}>
            <SpecialHeading>Partners</SpecialHeading>
              <LogoDiv className="logos">
                <img src={TRGLogo} alt="trg logo"/>
                <img src={YavapaiLogo} alt="yavapai logo"/>
                <img src={EARULogo} alt="earu logo"/>
                <img src={ConsentSolutionsLogo} alt="consent solutions logo"/>
                <img src={EightfoldLogo} alt="eightfold logo"/>
                <img src={EightfoldITLogo} alt="eightfold it logo"/>
              </LogoDiv>
          </div>
        </div>
      </section>
    </Layout>
    )
  
}

export default AboutPage
